import'./ChartsConfig';
import { Line } from 'react-chartjs-2';
import { ChartData } from './ChartsConfig';

type LineProps = {
    data: ChartData,
    options?: any,
    style?: any
};

const LineChart = (props: LineProps) => (
  <Line data={props.data} options={props.options} style={props.style} />
);

export default LineChart;
