import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export const useAnalyticsService = () => {
    const {get, patch, post} = useHttp('/analytics');

    return {
        getUserAnalytics: (mutators: HttpMutators, id: string) => get(mutators, {url: `/user/${id}`}),
        getOrganizationAnalytics: (mutators: HttpMutators, id: string) => get(mutators, {url: `/organization/${id}`}),
    };
};