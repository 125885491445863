import axios, {AxiosHeaders, AxiosRequestConfig} from 'axios';

import {authActions} from 'src/store/reducers/auth-reducer';
import {ERoutes} from 'src/types/enum/routes.enum';
import {HttpMutators} from './http-mutators';
import {showNotification} from '@mantine/notifications';
import {useClearStore} from 'src/store';
import {useNavigate} from 'react-router-dom';
import {userProfileActions} from 'src/store/reducers/user-reducer';
import {useTranslation} from 'react-i18next';

export type RequestParams = {payload?: any} & Omit<AxiosRequestConfig, 'data'>;

const axiosRequest = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Content-Type': 'application/json'},
});

export const useHttp = (baseSlug = '') => {

    const clearStore = useClearStore();
    const navigate = useNavigate();
    let refreshToken = authActions.useRefreshToken();
    const saveToken = authActions.useSaveToken();
    const saveProfile = userProfileActions.useSaveProfile();
    const {t} = useTranslation();
    let token = authActions.useToken();
    const useUpdateTosStatus = userProfileActions.useUpdateTosStatus();
    const userProfile = userProfileActions.useUserProfile();

    const request = async (params = {} as RequestParams, {loading, error, success}: HttpMutators) => {
        loading?.(true);
        params.url = `${baseSlug}${params.url || ''}`;

        const hardcodedAccesToken = localStorage.getItem('accessToken');
        const hardcodedRefreshToken = localStorage.getItem('refreshToken');
        
        if (!token && hardcodedAccesToken) {
            token = hardcodedAccesToken;
        }
        if (!refreshToken && hardcodedRefreshToken) {
            refreshToken = hardcodedRefreshToken;
        }
        
        if (token) {
            params.headers = {...params.headers, Authorization: `Bearer ${token}`} as unknown as AxiosHeaders;
        }

        let notSignIn401Err = false;
        try {
            const res = await axiosRequest({...params, data: params?.payload});
            if (params.responseType === 'blob') {
                success?.(res);
            } else {
                success?.(res.data);
            }
            loading?.(false);
            return res.data;
        } catch (err) {
            if (params.url === ERoutes.SIGN_IN && (err as any)?.response?.status === 401) {
                // if sign-in failed
                showNotification({
                    title: t('auth.signInNotification'),
                    message: t('auth.wrongCredentials'),
                    color: 'red',
                });
                clearStore();
                navigate(ERoutes.SIGN_IN, {replace: true});
            } else if ((err as any)?.response?.status === 401) {
                notSignIn401Err = true;
            } else if ( // case of blocking or not approved ToS
                userProfile.userId 
                && userProfile.isAdmin == false
                && (params.url?.startsWith(ERoutes.REQUEST) || params.url?.startsWith(ERoutes.EXECUTIONS)) 
                && (err as any)?.response?.status === 403
            ) {
                useUpdateTosStatus(userProfile.orgApprovedLatestToS == false, userProfile.tosMustBeApproved || false);
                error?.({status: (err as any)?.response?.status || 500, ...(err as any).response?.data});
            } else { // if any other error
                error?.({status: (err as any)?.response?.status || 500, ...(err as any)?.response?.data});
            }
        }
        
        // trying again with the refresh token
        if (notSignIn401Err && refreshToken) {
            
            const options = {
                headers: {
                    Accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            };
            try {
                const res = await axiosRequest.post('/access-token', {
                    refreshToken: refreshToken,
                    options,
                });
                
                const newToken = res.data.token;
                saveToken({token: newToken, refreshToken: refreshToken});
                saveProfile(newToken);
                token = newToken;
                // remake the same request with updated access token
                params.headers = {
                    Authorization: `Bearer ${newToken}`,
                };

                // prevent route duplication when retrying a request while using a base slug
                if (params.url === baseSlug) {
                    params.url = '';
                }
                // remove the first slash from the url
                const splitUrl = params?.url?.split('/');
                splitUrl?.shift();
                params.url = splitUrl?.join('/');
                request(params, {loading, error, success});
                return;
            } catch (error) {
                console.error("bad refresh token!");
            }
        }
        loading?.(false);
    };

    return {
        get: (mutators: HttpMutators, params?: RequestParams | null) => request({...params, method: 'GET'}, mutators),
        post: (mutators: HttpMutators, params?: RequestParams | null) => request({...params, method: 'POST'}, mutators),
        patch: (mutators: HttpMutators, params?: RequestParams | null) =>
            request({...params, method: 'PATCH'}, mutators),
        del: (mutators: HttpMutators, params?: RequestParams | null) =>
            request({...params, method: 'DELETE'}, mutators),
    };
};
