
import {Button, Table, Title, Text} from '@mantine/core';
import useTranslation from 'src/i18next.config';
import { useAnalyticsService } from 'src/services/analytics';
import {showNotification} from '@mantine/notifications';
import { Analytic } from 'src/types/models/Analytic';
import {User} from 'src/types/models/User';
import { Organization } from 'src/types/models/Organization';
import { useUserService } from 'src/services';
import { useOrganizationService } from 'src/services/organization';
import { useEffect, useState } from "react";
import { IconSquareRoundedX } from '@tabler/icons-react';
import { ChartData } from './charts/ChartsConfig';
import DoughnutChart from './charts/DoughnutChart';
import LineChart from './charts/LineChart';

export function AnalyticsList() {
    const {t} = useTranslation();
    const analyticsService = useAnalyticsService();
    const userService = useUserService();
    const organizationService = useOrganizationService();
    const [users, setUsers] = useState<User[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [analytics, setAnalytics] = useState<Analytic[]>([]);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [doughnutData, setDoughnutData] = useState<ChartData>({ labels: [], datasets: [] });
    const [lineChartData, setLineChartData] = useState<ChartData>({ labels: [], datasets: [] });
    const [selectedItemName, setSelectedItemName] = useState('');

    useEffect(() => {
        if (showAnalytics) {
            formatLineChartData();
            formatDoughnutData();
        }
    }, [showAnalytics, analytics]);


    function formatDate(timestamp: number) {
        const date = new Date(timestamp);
        return date.toLocaleDateString();
    }

    function sumUsageTime(data: Analytic[], label: string, isPlanQK: boolean) {
        const sumData = data.filter((item: Analytic) => formatDate(item.doneDate) === label && item.isFromPlanQK === isPlanQK)
                            .reduce((sum: any, item: Analytic) => sum + item.usageTime, 0);
        return sumData;
    }

    function formatLineChartData() {
        const labels = analytics.map(items => formatDate(items.doneDate))
                        .filter((label, index, arr) => arr.indexOf(label) === index);

        const planQKData = labels.map((label) => sumUsageTime(analytics, label, true));
        const normalData = labels.map((label) => sumUsageTime(analytics, label, false));

        const lineChartData = {
            labels,
            datasets: [
                {
                    label: t('analytics.charts.labels.planQK'),
                    data: planQKData,
                    borderColor: ['rgb(255, 99, 132)'],
                    backgroundColor: ['rgba(255, 99, 132, 0.5)']
                },
                {
                    label: t('analytics.charts.labels.qperfect'),
                    data: normalData,
                    borderColor: ['rgb(53, 162, 235)'],
                    backgroundColor: ['rgba(53, 162, 235, 0.5)'],
                },
            ],
        };
        setLineChartData(lineChartData);
    }

    function formatDoughnutData() {
        const labels = [t('analytics.charts.labels.planQK'), t('analytics.charts.labels.qperfect')]
        const planQKData = analytics.filter(data => data.isFromPlanQK).reduce((total, item) => total + item.usageTime, 0);
        const normalData = analytics.filter(data => !data.isFromPlanQK).reduce((total, item) => total + item.usageTime, 0);

        const doughnutData = {
            labels,
            datasets: [
                {
                    label: t('analytics.charts.labels.miliseconds'),
                    data: [planQKData, normalData],
                    borderColor: [
                        'rgb(255, 99, 132)',
                        'rgb(53, 162, 235)',
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(53, 162, 235, 0.5)'
                    ]
                }
            ],
        };
        setDoughnutData(doughnutData);
    }


    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
    };

    useEffect(function() {
        Promise.all([
            userService.getUsers({
                error: error => {
                    console.error(error);
                },
                success: res => {
                    return res.data;
                },
            }),
            organizationService.getOrganizations({
                error: error => {
                    console.error(error);
                },
                success: res => {
                    return res.data;
                },
            })
        ])
        .then(([users, organizations]) => {
            setUsers(users);
            setOrganizations(organizations);
        });
    }, [])


    async function getAnalytics(id: string, isUser: boolean) {
        const analyticsFunction = isUser
            ? analyticsService.getUserAnalytics
            : analyticsService.getOrganizationAnalytics;

        await analyticsFunction (
            {
                error: error => {
                    showNotification({
                        title: t('errorFetchingData'),
                        message: t('errorFetchingDataMessage'),
                        color: 'red',
                    });
                },
                success: res => {
                    setAnalytics(res);
                },
            },
            id
        )
    }

    const userRows = users !== undefined && users.map((user: User) => (
        <tr key={user._id}>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>
                <Button
                    color="cyan.8"
                    variant="outline"
                    onClick={() => {
                        displayAnalytics(user._id, true, `${user.lastName}, ${user.firstName}`);
                    }}
                >
                    {t('analytics.seeAnalyticsBtn')}
                </Button>
            </td>
        </tr>
    ));

    async function displayAnalytics(id: string, isUser: boolean, name: string) {
        await getAnalytics(id, isUser);
        setSelectedItemName(name);
        setShowAnalytics(true);
    }

    const organizationRows = organizations !== undefined && organizations.map((organization: Organization) => (
        <tr key={organization._id}>
            <td>{organization.name}</td>
            <td>
                <Button
                    color="cyan.8"
                    variant="outline"
                    onClick={() => {
                        displayAnalytics(organization._id, false, organization.name);
                    }}
                >
                    {t('analytics.seeAnalyticsBtn')}
                </Button>
            </td>
        </tr>
    ));

    return (
        <>
            <Title >{t('analytics.titles.pageTitle')}</Title>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: 50, marginLeft: 30}}>
                <Title style={{fontSize: 20, marginLeft: 20, marginBottom: 20}}>{t('analytics.titles.organizations')}</Title>
                <Table style={{width: 20}}>
                    <thead className="table-head">
                        <tr>
                            <th style={{minWidth: '100px'}}>{t('Name')}</th>
                            <th>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>{organizationRows}</tbody>
                </Table>

                <Title style={{fontSize: 20, marginLeft: 20, marginTop: 50, marginBottom: 20}}>{t('analytics.titles.users')}</Title>
                <Table style={{width: 20}}>
                    <thead className="table-head">
                        <tr>
                            <th style={{minWidth: '100px'}}>{t('firstName')}</th>
                            <th style={{minWidth: '100px'}}>{t('lastName')}</th>
                            <th>{t('actions')}</th>
                        </tr>
                    </thead>
                    <tbody>{userRows}</tbody>
                </Table>
                </div>

                {showAnalytics &&
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',
                        alignItems: 'center', rowGap: 30, marginLeft: 200}}>
                        <IconSquareRoundedX style={{marginLeft: 'auto', cursor: 'pointer'}}
                            onClick={() => setShowAnalytics(false)}
                        />
                        <Title style={{ fontSize: 20, marginBottom: 10, marginRight: 'auto' }}>{selectedItemName}</Title>
                        <Title style={{ fontSize: 13, marginRight: 'auto' }}>{t('analytics.titles.nbExecutions')} : {analytics.length}</Title>
                        <div>
                            <Title style={{ fontSize: 15, textAlign: 'center', marginBottom: 15 }}>{t('analytics.charts.titles.cpuUsage')}</Title>
                            <LineChart options={options} data={lineChartData} style={{width: 400}}/>
                        </div>
                        <div style={{width: 250}}>
                            <DoughnutChart data={doughnutData}/>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}