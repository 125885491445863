import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export const useOrganizationService = () => {
    const {get, post, patch} = useHttp('/organization');

    return {
        createOrganization: (mutators: HttpMutators, payload: any) => post(mutators, {payload}),
        getOrganizations: (mutators: HttpMutators) => get(mutators),
        getOrganization: (mutators: HttpMutators, id: string) => get(mutators, {url: `/${id}`}),
        patchOrganization: (mutators: HttpMutators, id: string, payload: any) =>
            patch(mutators, {url: `/${id}`, payload}),
    };
};

export const useOrgTosService = () => {
    const {get, post} = useHttp('/tos');

    return {
        getTosByOrg: (mutators: HttpMutators, id: string) => get(mutators, {url: `/organization/${id}`}),
        postOrganizationToS: (mutators: HttpMutators, id: string, payload: any) =>
            post(mutators, {url: `/organization/${id}`, payload}),
    };
};
