import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend
);

export type ChartData = {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      borderColor: string[];
      backgroundColor: string[];
    }[];
};