import {HttpMutators} from './http-mutators';
import {useHttp} from './http';

export type SignInPayload = {
    email: string;
    password: string;
};

export type requestNewTokenPayload = {
    refreshToken: string;
};

export const useAuthService = () => {
    const {post, get} = useHttp();

    return {
        signIn: (mutators: HttpMutators, payload: SignInPayload) =>
            post(mutators, {
                url: '/sign-in',
                payload,
            }),
        requestNewToken: (mutators: HttpMutators, payload: requestNewTokenPayload) =>
            get(mutators, {
                url: '/access-token',
                payload,
            }),
    };
};

export type RequestMimiqAuthToken = {
    userId: string
}

export type CreateMimiqAuthToken = {
    userId: string
}

export type RevokeMimiqAuthToken = {
    userId: string
}

export const useMimiqAuthTokenService = () => {
    const {post, get} = useHttp();

    return {
        getToken: (mutators: HttpMutators, payload: RequestMimiqAuthToken) =>
            get(mutators, {
                url: '/get-mimiq-token',
                params: payload
            }),
        createToken: (mutators: HttpMutators, payload: CreateMimiqAuthToken) =>
            post(mutators, {
                url: '/create-mimiq-token',
                payload
            }),
        revokeToken: (mutators: HttpMutators, payload: RevokeMimiqAuthToken) =>
            post(mutators, {
                url: '/revoke-mimiq-token',
                payload
            }),
        
    }
}