import { useEffect, useState } from 'react';

import { ToS } from 'src/types/models/ToS';
import { useOrgTosService } from 'src/services/organization';
import useTranslation from 'src/i18next.config';
import { userProfileActions } from 'src/store/reducers/user-reducer';
import { Divider, Paper, Stack, Title } from '@mantine/core';

export function ReadTos() {

    const { getTosByOrg } = useOrgTosService();
    const {t} = useTranslation();
    const userProfile = userProfileActions.useUserProfile();

    const [tos, setTos] = useState<ToS>();

    useEffect(() => {
        getTosByOrg(
            {
                success: (fetchedTos: ToS) => {
                    setTos(fetchedTos);
                },
            },
            userProfile.organizationId 
        );
    }, []);

    if (tos) {
        return (
            <Paper
                p="md"
                shadow="md"
                component="form"
                noValidate
                style={{
                    width: '80%', 
                    margin: 'auto'
                }}
            >
                <Stack spacing="lg" >
                    <Title order={1}>{t('tos.tos')}</Title>
                    <Title order={2}>{`Into effect starting: ${new Date(tos.intoEffectAt).toLocaleDateString()}`}</Title>
                    <Divider />
                    <div style={{
                        overflowX: 'hidden',   
                        wordWrap: 'break-word',
                    }}
                    dangerouslySetInnerHTML={{ __html: tos.contents }} />
                    <Divider />
                </Stack>
            </Paper>
        );
    } 
    return <p>{t('tos.noToSFound')}</p>
}
