import {Routes, Route, Navigate} from 'react-router-dom';
import { useEffect, useState } from 'react';

import AppHeader from './components/core/header/AppHeader';
import {AppNavbar} from './components/core/navbar/AppNavbar';
import {AppShell} from '@mantine/core';
import {authActions} from './store/reducers/auth-reducer';
import { ERibbonColors } from './types/enum/ribbon-colors-enum';
import {ERoles} from './types/enum/roles.enum';
import {ERoutes} from './types/enum/routes.enum';
import routes from "./routes";
import {userProfileActions} from './store/reducers/user-reducer';
import TopRibbon from './components/core/header/TopRibbon';
import { ToS } from './types/models/ToS';
import useTosService from './services/tos';
import useTranslation from './i18next.config';


type MapRouteType = {
    path: string;
    Component: () => JSX.Element;
};

function App() {

    const token = authActions.useToken();
    const {getTosList} = useTosService();
    const userProfile = userProfileActions.useUserProfile();
    const {t} = useTranslation();

    const [topRibbonColor, setTopRibbonColor] = useState<ERibbonColors>(ERibbonColors.NONE);
    const [topRibbonShown, setTopRibbonShown] = useState(false);
    const [topRibbonText, setTopRibbonText] = useState('');

    useEffect(() => {
        if (!token) {
            setTopRibbonShown(false);
        } else if(!userProfile.isAdmin) {
            getTosList(
                {
                    success: (fetchedToS: ToS[]) => {
                        if(fetchedToS.length > 0) {
                            const latestTos = fetchedToS.reduce((prev, current) => (prev.version! > current.version!) ? prev : current);
                            setTopRibbonShown((latestTos.approved == false || userProfile.tosMustBeApproved == true));
                            setTopRibbonColor(userProfile.tosMustBeApproved ? ERibbonColors.DANGER : !latestTos.approved ? ERibbonColors.WARNING: ERibbonColors.NONE);
                            const warningText = userProfile.tosMustBeApproved ? t('tos.tosBlocking'): t('tos.tosWarning', {date: new Date(latestTos.intoEffectAt).toDateString()});
                            setTopRibbonText(warningText);
                        }
                    }
                }
            );
        }
    }, [token]);

    useEffect(() => {
        if (!userProfile.isAdmin) {
            setTopRibbonShown((userProfile.orgApprovedLatestToS == false || userProfile.tosMustBeApproved == true));
            setTopRibbonColor(userProfile.tosMustBeApproved ? ERibbonColors.DANGER : !userProfile.orgApprovedLatestToS ? ERibbonColors.WARNING: ERibbonColors.NONE);
            getTosList(
                {
                    success: (fetchedToS: ToS[]) => {
                        if(fetchedToS.length > 0) {
                            const latestTos = fetchedToS.reduce((prev, current) => (prev.version! > current.version!) ? prev : current);
                            const warningText = userProfile.tosMustBeApproved ? t('tos.tosBlocking'): t('tos.tosWarning', {date: new Date(latestTos.intoEffectAt).toDateString()});
                            setTopRibbonText(warningText);
                        }
                    }
                }
            );
        }
    }, [userProfile.orgApprovedLatestToS, userProfile.tosMustBeApproved]);

    function getHasAccess(roles: string[]) {
        // User not authenticated
        if (!token) {
            return false;
        }
        
        const hasAccess = roles
            .map(role => {
                switch (role) {
                    case ERoles.ADMIN:
                        return userProfile.isAdmin;
                    case ERoles.ORGANIZATION_MANAGER:
                        return userProfile.isOrganizationManager;
                    case ERoles.USER:
                        return userProfile.isUser;
                    case ERoles.BUSINESS_MANAGER:
                        return userProfile.isBusinessManager;
                    default:
                        return false;
                }
            })
            .reduce(
                (previewsHasAccess: boolean, currentHasAccess: boolean) => previewsHasAccess || currentHasAccess,
                false
            );
        return hasAccess;
    }

    function mapGuestRoutes(route: MapRouteType) {
        const {path, Component} = route;

        return <Route key={path} path={path} element={<Component />} />;
    }

    function mapUserRoutes(route: MapRouteType, hasAccess: boolean | undefined) {
        const {path, Component} = route;
        return (
            <Route
                key={path}
                path={path}
                element={hasAccess ? <Component /> : <Navigate to={ERoutes.SIGN_IN} replace />}
            />
        );
    }

    return (
        <AppShell
            navbarOffsetBreakpoint="sm"
            asideOffsetBreakpoint="sm"
            navbar={<AppNavbar></AppNavbar>}
            header={<AppHeader></AppHeader>}
            padding="xs"
        >
            {topRibbonShown && <TopRibbon closable={true} color={topRibbonColor} message={topRibbonText} onClose={() => setTopRibbonShown(false)} shown={topRibbonShown} />}
            <Routes>
                {routes.publicRoutes.map(mapGuestRoutes)}

                {routes.privateAdminRoutes.map(route => mapUserRoutes(route, getHasAccess([ERoles.ADMIN])))}
                {routes.privateManagerRoutes.map(route =>
                    mapUserRoutes(route, getHasAccess([ERoles.ADMIN, ERoles.ORGANIZATION_MANAGER]))
                )}
                {routes.privateUserRoutes.map(route =>
                    mapUserRoutes(route, getHasAccess([ERoles.ADMIN, ERoles.ORGANIZATION_MANAGER, ERoles.USER, ERoles.BUSINESS_MANAGER]))
                )}
                {routes.privateBusinesManagerRoutes.map(route =>
                    mapUserRoutes(route, getHasAccess([ERoles.ADMIN, ERoles.BUSINESS_MANAGER]))
                )}
            </Routes>
        </AppShell>
    );
}

export default App;
