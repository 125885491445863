import jwt_decode from 'jwt-decode';
import {Reducer} from 'redux';
import {useDispatch, useSelector} from 'react-redux';

import {Action} from './action';
import { ERoles } from 'src/types/enum/roles.enum';
import {EUserProfileActionType} from 'src/types/enum/profile-action-type.enum';
import { JwtPayload } from 'src/types/interfaces/jwt-payload';
import {RootState} from './root-reducer';

const initialState = {
    isAdmin: false,
    isUser: false,
    isOrganizationManager: false,
    isBusinessManager: false,
    userId: '',
    organizationId: '',
}as IUserProfile;

export interface IUserProfile {
    isAdmin: boolean;
    isUser: boolean;
    isOrganizationManager: boolean;
    isBusinessManager: boolean;
    userId: string;
    organizationId: string;
    orgApprovedLatestToS?: boolean;
    tosMustBeApproved?: boolean;
}

export interface IUserProfileAction extends Action {
    type: EUserProfileActionType;
    payload: IUserProfile;
}

const useUserProfile = (): IUserProfile => {
    return useSelector<RootState, IUserProfile>(state => state.userReducer);
};

const useRemoveProfile = () => {
    const dispatch = useDispatch();
    return () => dispatch({type: EUserProfileActionType.removeProfile});
};

const useSaveProfile = () => {
    const dispatch = useDispatch();
    return (res: string) => {
        const decoded: JwtPayload = jwt_decode(res);
        const userProfile = {
            isAdmin: decoded.role === ERoles.ADMIN,
            isUser: ERoles.USER === decoded.role,
            isOrganizationManager: ERoles.ORGANIZATION_MANAGER === decoded.role,
            isBusinessManager: ERoles.BUSINESS_MANAGER === decoded.role,
            userId: decoded._id,
            organizationId: decoded.organizationId,
        } as IUserProfile;
        if (decoded.orgApprovedLatestToS) {
            userProfile.orgApprovedLatestToS = decoded.orgApprovedLatestToS
        }
        if (decoded.tosMustBeApproved) {
            userProfile.tosMustBeApproved = decoded.tosMustBeApproved;
        }
        return dispatch({type: EUserProfileActionType.saveProfile, payload: userProfile})};
};

const useUpdateTosStatus = () => {
    const dispatch = useDispatch();
    return (orgApprovedLatestToS: boolean, tosMustBeApproved: boolean) => dispatch({type: EUserProfileActionType.updateTosStatus, payload: {
        orgApprovedLatestToS,
        tosMustBeApproved
    }});
}

export const userProfileActions = {useRemoveProfile, useSaveProfile, useUpdateTosStatus, useUserProfile}

export const userReducer: Reducer<IUserProfile, IUserProfileAction> = (
    state = initialState,
    action: IUserProfileAction
) => {
    switch (action.type) {
        case EUserProfileActionType.removeProfile:
            return {
                ...state,
                ...initialState
            };
        case EUserProfileActionType.saveProfile:
            return {
                ...state,
                ...action.payload
            };
        case EUserProfileActionType.updateTosStatus:
            return {
                ...state,
                orgApprovedLatestToS: action.payload.orgApprovedLatestToS,
                tosMustBeApproved: action.payload.tosMustBeApproved
            };
        default:
            return state;
    }
};