import './ChartsConfig';
import { Doughnut } from 'react-chartjs-2';
import { ChartData } from './ChartsConfig';

type DoughnutProps = {
    data: ChartData,
    options?: any,
    style?: any
};

const DoughnutChart = (props: DoughnutProps) => (
    <Doughnut data={props.data} options={props.options} style={props.style} />
);

export default DoughnutChart;