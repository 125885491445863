import { Button, Flex, Paper, Text, Loader, CopyButton } from "@mantine/core"
import { useEffect, useState } from "react";
import { useMimiqAuthTokenService } from "src/services/authentication"
import { userProfileActions } from "src/store/reducers/user-reducer";

import useTranslation from 'src/i18next.config';


export default function AuthToken() {

    const {
        getToken,
        createToken,
        revokeToken
    } = useMimiqAuthTokenService();

    const {t} = useTranslation();
    const userProfile = userProfileActions.useUserProfile();

    const [authToken, setAuthToken] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            setLoading(true);
            await getToken({
                success: ({authToken}: {authToken:string}) => {
                    setAuthToken(authToken)
                }
            },
            {
                userId: userProfile.userId   
            })
            setLoading(false);
        })()
    }, []);

    const _createToken = async () => {
        setLoading(true);
        await createToken({
            success: ({authToken}: {authToken:string}) => {
                setAuthToken(authToken)
            }
        },
        {
            userId: userProfile.userId   
        })
        setLoading(false);

    }

    const _revokeToken = async () => {
        setLoading(true);
        await revokeToken({
            success: () => {
                setAuthToken("")
            }
        },
        {
            userId: userProfile.userId   
        })
        setLoading(false);
    }

    return (
        <Paper shadow="md" p="md" withBorder>
            {
                loading ? <Flex justify="center" align="center"><Loader /></Flex> :
                authToken === '' ? 
                    <Flex align="center" justify="center" gap={8} direction="column">
                        <Text fs="italic">{t('mimiqAuthToken.noToken')}</Text>
                        <Button
                            color="cyan.8"
                            variant="outline"
                            onClick={() => _createToken()}
                        >
                            {t('mimiqAuthToken.createToken')}
                        </Button>
                    </Flex> 
                    :
                    <Flex align="center" justify="space-between">
                        <HalfHiddenToken token={authToken} />
                        <Flex gap={8}>
                            <CopyButton value={authToken}>
                                {({ copied, copy }) => (
                                    <Button 
                                        variant="outline"
                                        color={copied ? 'teal.8' : 'blue.8'} 
                                        onClick={copy}
                                    >
                                        {copied ? t('mimiqAuthToken.tokenCopied') : t('mimiqAuthToken.copyToken')}
                                    </Button>
                                )}
                            </CopyButton>
                            <Button 
                                variant="outline"
                                color="red.8"
                                onClick={_revokeToken}
                            >
                                {t('mimiqAuthToken.revokeToken')}
                            </Button>
                        </Flex>
                    </Flex>
            }
        </Paper>
    )
}

interface HalfHiddenTokenProps {
    token: string;
}

const HalfHiddenToken = ({token}: HalfHiddenTokenProps) => {

    const halfLength = Math.ceil(token.length / 2);
    const visiblePart = token.substring(0, halfLength);
    const hiddenPart = '•'.repeat(token.length - halfLength); // Replace second half with bullets

    return (
        <Flex>
            <Text>{visiblePart}</Text>
            <Text>{hiddenPart}</Text>
        </Flex>
    )
}