import {showNotification} from '@mantine/notifications';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import { ERoutes } from 'src/types/enum/routes.enum';
import { ToS } from 'src/types/models/ToS';
import { ToSFormApproval } from './ToSFormApproval';
import {useNavigate} from 'react-router-dom';
import { userProfileActions } from 'src/store/reducers/user-reducer';
import { useOrgTosService } from 'src/services/organization';
import useTranslation from 'src/i18next.config';
import useTosService from 'src/services/tos';

export function ApproveToS() {

    const {id} = useParams();
    const {t} = useTranslation();
    const {getTos} = useTosService();
    const navigate = useNavigate();
    const {postOrganizationToS} = useOrgTosService();
    const userProfile = userProfileActions.useUserProfile();

    const [tos, setToS] = useState<ToS>();

    useEffect(() => {
        if (id != null) {
            getTos(
                {
                    success: (values: ToS) => {
                        setToS(values);
                    },
                },
                id
            );
        }
    }, []);

    async function approveToS(payload: string) {
        if (id != null) {
            await postOrganizationToS(
                {
                    error: () => {
                        showNotification({
                            title: t('tos.editToSNotification'),
                            message: t('tos.editToSErrorMessage'),
                            color: 'red',
                        });
                    },
                    success: () => {
                        showNotification({
                            title: t('tos.editToSNotification'),
                            message: t('tos.editToSSuccessMessage'),
                            color: 'green',
                        });
                        navigate(ERoutes.TOS);
                    },
                },
                userProfile.organizationId,
                {
                    tos: payload
                }
            );
        }
    }

    if (tos) {
        return (
            <>
                <ToSFormApproval
                    initialToS={tos}
                    title={t('tos.tos')}
                    submitBtnLbl="tos.approve"
                    onApprove={approveToS}
                ></ToSFormApproval>
            </>
        );
    } else {
        return <></>;
    }
}
